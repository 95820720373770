import React from 'react';

import PageLayout from '../components/page-layout';
import SEO from '../components/seo';
import { ContentWrapper, ContentContainer } from '../components/content-layout';

const NotFoundPage = () => {
  const browser = typeof window !== 'undefined' && window;
  return (
    browser && (
      <PageLayout>
        <SEO title="404: Page not found" />
        <ContentWrapper>
          <ContentContainer>
            <h1>404: Page not found</h1>
            <p>Sorry, the page you were looking for can not be found.</p>
          </ContentContainer>
        </ContentWrapper>
      </PageLayout>
    )
  );
};

export default NotFoundPage;
